import { combineReducers } from '@reduxjs/toolkit';
import devices from './devicesSlice';
import employees from './employeesSlice';
import subscription from './subscriptionSlice';

const bravoReducers = combineReducers({
    devices,
    employees,
    subscription,
});

export default bravoReducers;
