import React from 'react';

function EnvironmentBravoCloud() {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'development':
            return (
                <div className='flex flex-1 flex-row justify-center' style={{ backgroundColor: 'red', color: 'white' }}>
                    DEVELOPMENT
                </div>
            );
        case 'test':
            return (
                <div
                    className='flex flex-1 flex-row justify-center'
                    style={{ backgroundColor: 'purple', color: 'white' }}
                >
                    TEST - {process.env.REACT_APP_VERSION}
                </div>
            );
        default:
            return null;
    }
}

export default EnvironmentBravoCloud;
