import React from 'react';
import { Redirect } from 'react-router-dom';

const SubscriptionAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: '/apps/subscription-app/subscribe',
            component: React.lazy(() => import('./subscribe/Subscribe')),
        },
        {
            path: '/apps/subscription-app',
            component: () => <Redirect to='/apps/subscription-app/subscribe' />,
        },
    ],
};

export default SubscriptionAppConfig;
