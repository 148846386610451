import { all, spawn } from 'redux-saga/effects';

import userSagas from './user.sagas';
import devicesSagas from './devices.sagas';
import employeesSagas from './employees.sagas';
import subscriptionSagas from './subscription.sagas';
import deviceWebSocketSagas from './deviceWebSocket.sagas';

export default function* ourSagas() {
    yield all([
        spawn(userSagas),
        spawn(devicesSagas),
        spawn(employeesSagas),
        spawn(subscriptionSagas),
        spawn(deviceWebSocketSagas),
    ]);
}
