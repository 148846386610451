import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse/core/formsy';
import * as yup from 'yup';
import { Button, InputAdornment } from '@material-ui/core';
import { parse } from 'qs';
import { has, isNil } from 'lodash';
import Axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${'assets/images/auth/login-background.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
    },
}));

const ResetPassword = (props) => {
    const classes = useStyles();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const paramsRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        paramsRef.current = parse(document.location.search.substring(1));
        if (!has(paramsRef.current, 'email') || !has(paramsRef.current, 'resetToken')) {
            history.push('/login');
        }
    }, []);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        setIsFormValid(true);
    }

    const handleSubmit = async (model) => {
        try {
            setIsLoading(true);
            const url = `${process.env.REACT_APP_AUTH_SERVER_API_HOST}/api/users/reset_password`;
            await Axios.patch(url, {
                email: paramsRef.current.email,
                resetToken: paramsRef.current.resetToken,
                password: model.password,
                passwordConfirmation: model['password-confirm'],
            });
            dispatch(
                showMessage({
                    message: 'Your password is successfully reset, please use your new password to login',
                    variant: 'success',
                })
            );
        } catch (err) {
            dispatch(
                showMessage({
                    message: 'An error occured, please try again',
                    variant: 'error',
                })
            );
        } finally {
            setIsLoading(false);
            history.push('/login');
        }
    };

    return (
        <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
            <div className='flex flex-col items-center justify-center w-full'>
                <FuseAnimate animation='transition.expandIn'>
                    <Card className='w-full max-w-512 rounded-8'>
                        <CardContent className='flex flex-col items-center justify-center p-32'>
                            <div className='m-8'>
                                <Icon className='text-96' color='action'>
                                    vpn_key
                                </Icon>
                            </div>
                            <Typography variant='h5' className='text-center mb-16'>
                                Reset your password!
                            </Typography>
                            <Typography className='text-center w-full mb-32' color='textSecondary'>
                                Please enter your new password
                            </Typography>
                            <Formsy
                                onValidSubmit={handleSubmit}
                                onValid={enableButton}
                                onInvalid={disableButton}
                                ref={formRef}
                                className='flex flex-col justify-center w-full'
                            >
                                <TextFieldFormsy
                                    className='mb-16'
                                    type='password'
                                    name='password'
                                    label='New password'
                                    validations={{
                                        passwordStrengh: (values, value) => {
                                            if (value) {
                                                const schema = yup
                                                    .string()
                                                    .min(8, 'Password needs at least 8 characters')
                                                    .matches(/[0-9]/, 'Password needs at least one number')
                                                    .matches(/[A-Z]/, 'Password needs at least one uppercase letter')
                                                    .matches(/[a-z]/, 'Password needs at least one lowercase letter');

                                                try {
                                                    schema.validateSync(value);
                                                } catch (err) {
                                                    return err.message;
                                                }
                                                return true;
                                            }
                                            return true;
                                        },
                                        equalsField: 'password-confirm',
                                    }}
                                    validationErrors={{
                                        equalsField: 'Passwords do not match',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Icon className='text-20' color='action'>
                                                    vpn_key
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant='outlined'
                                    required
                                    disabled={isLoading}
                                    value=''
                                />
                                <TextFieldFormsy
                                    className='mb-16'
                                    type='password'
                                    name='password-confirm'
                                    label='Confirm new password'
                                    validations='equalsField:password'
                                    validationErrors={{
                                        equalsField: 'Passwords do not match',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Icon className='text-20' color='action'>
                                                    vpn_key
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant='outlined'
                                    required
                                    disabled={isLoading}
                                    value=''
                                />

                                {isLoading ? (
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                        className='w-full mx-auto mt-16 normal-case'
                                        aria-label='LOADING'
                                        disabled
                                        value='legacy'
                                    >
                                        Loading ...
                                    </Button>
                                ) : (
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                        className='w-full mx-auto mt-16 normal-case'
                                        disabled={!isFormValid}
                                        value='legacy'
                                    >
                                        RESET PASSWORD
                                    </Button>
                                )}
                            </Formsy>

                            <div className='flex flex-col items-center justify-center pt-32 pb-24'>
                                <Link className='font-medium' to='/pages/auth/login'>
                                    Go back to login
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>
    );
};

export default ResetPassword;
