import { authRoles } from 'app/auth';
import ValidateEmail from './ValidateEmail';

const ValidateEmailConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/validate_email',
            component: ValidateEmail,
        },
    ],
};

export default ValidateEmailConfig;
