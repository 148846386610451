import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { sortBy } from 'lodash';

export const getCompanies = createAsyncThunk('auth/companies/getCompanies', async (params, { dispatch, getState }) => {
    try {
        dispatch(setCompaniesLoading());
        const employeeId = getState().auth.user.data.id;
        const response = await Axios.get(`/api/employees/${employeeId}/companies`);
        const data = await response.data;
        dispatch(getCompaniesSuccess(data));
    } catch (err) {
        console.info(err);
    }
});

const initialState = {
    loading: true,
    error: null,
    items: [],
};

const companiesSlice = createSlice({
    name: 'auth/companies',
    initialState,
    reducers: {
        getCompaniesSuccess: (state, action) => {
            state.error = null;
            state.loading = false;
            state.items = sortBy(action.payload, 'companyName');
        },
        getCompaniesFailed: (state, action) => {
            state.loading = false;
            state.error = 'Something went wrong';
            state.items = [];
        },
        setCompaniesLoading: (state, action) => {
            state.loading = true;
        },
    },
    extraReducers: {
        // [getCompanies.fulfilled]: (state, action) => action.payload,
    },
});

export const { getCompaniesSuccess, getCompaniesFailed, setCompaniesLoading } = companiesSlice.actions;

export default companiesSlice.reducer;
