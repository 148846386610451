import React from 'react';
import { Redirect } from 'react-router-dom';

const AlarmsAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: '/apps/alarms-app/active-alarms',
            component: React.lazy(() => import('./active-alarms/ActiveAlarms')),
        },
        {
            path: '/apps/alarms-app',
            component: () => <Redirect to='/apps/alarms-app/active-alarms' />,
        },
    ],
};

export default AlarmsAppConfig;
