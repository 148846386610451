import React from 'react';
import { Redirect } from 'react-router-dom';

const DevicesAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: '/apps/devices-app/devices/:safeSystemId',
            component: React.lazy(() => import('./device/Device')),
        },
        {
            path: '/apps/devices-app/devices',
            component: React.lazy(() => import('./devices/Devices')),
        },
        {
            path: '/apps/devices-app',
            component: () => <Redirect to='/apps/devices-app/devices' />,
        },
    ],
};

export default DevicesAppConfig;
