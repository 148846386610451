import { createSlice, createAction } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

export const getEmployees = createAction('bravo/devices/getEmployees');

const initialState = {
    loading: true,
    error: null,
    items: [],
};

const employeesSlice = createSlice({
    name: 'bravo/employees',
    initialState,
    reducers: {
        getEmployeesStart: (state, action) => {
            state.items = [];
            state.loading = true;
            state.error = null;
        },
        getEmployeesSuccess: (state, action) => {
            state.items = sortBy(action.payload, 'fullName');
            state.loading = false;
            state.error = null;
        },
        getEmployeesFailed: (state, action) => {
            state.items = [];
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { getEmployeesStart, getEmployeesSuccess, getEmployeesFailed } = employeesSlice.actions;

export default employeesSlice.reducer;
