import { padStart, isEmpty, isNil } from 'lodash';

export const getSafeSystemId = (systemId) => {
    return systemId.replace(/:/g, '');
};

export const getDeviceVersion = (v) => {
    // revision
    let version = v.toString();
    let revision = version.slice(-2);
    if (isEmpty(revision)) {
        revision = '0';
    }
    revision = padStart(revision, 2, '0');

    // minor
    version = version.slice(0, -2);
    let minor = version.slice(-2);
    if (isEmpty(minor)) {
        minor = '0';
    }
    minor = padStart(minor, 2, '0');

    // major
    version = version.slice(0, -2);
    let major = version.slice(-2);
    if (isEmpty(major)) {
        major = '0';
    }

    return `${parseInt(major)}.${minor}.${revision}`;
};

export const getCommercialProductName = (v) => {
    if (!isNil(v)) {
        switch (v.toUpperCase()) {
            case 'ABBIDCC':
                return 'ABBI DCC Touch';
            case 'MFBT':
                return 'Bravo Touch';
            default:
                break;
        }
    }
    return '';
};
