import { TextFieldFormsy, CheckboxFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import * as yup from 'yup';
import { submitRegister } from 'app/auth/store/registerSlice';
import { FormControlLabel } from '@material-ui/core';

function RegisterForm(props) {
    const dispatch = useDispatch();
    const register = useSelector(({ auth }) => auth.register);

    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        if (register.error && (register.error.username || register.error.password || register.error.email)) {
            formRef.current.updateInputsWithError({
                ...register.error,
            });
            disableButton();
        }
    }, [register.error]);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        setIsFormValid(true);
    }

    function handleSubmit(model) {
        dispatch(submitRegister(model));
    }

    return (
        <div className='w-full'>
            <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className='flex flex-col justify-center w-full'
            >
                {/* <TextFieldFormsy
                    className='mb-16'
                    type='text'
                    name='company'
                    label='Company'
                    validations={{
                        minLength: 2,
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 2',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    business
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    disabled={register.loading}
                    value=''
                /> */}
                <TextFieldFormsy
                    className='mb-16'
                    size='small'
                    type='text'
                    name='firstName'
                    label='First name'
                    validations={{
                        minLength: 2,
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 2',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    person
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                    disabled={register.loading}
                    value=''
                />
                <TextFieldFormsy
                    className='mb-16'
                    size='small'
                    type='text'
                    name='lastName'
                    label='Last name'
                    validations={{
                        minLength: 2,
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 2',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    person
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                    disabled={register.loading}
                    value=''
                />
                <TextFieldFormsy
                    className='mb-16'
                    size='small'
                    type='text'
                    name='email'
                    label='Email'
                    validations='isEmail'
                    validationErrors={{
                        isEmail: 'Please enter a valid email',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    email
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                    disabled={register.loading}
                    value=''
                />
                <div className='mb-24 mt-8 flex items-center justify-center'>
                    <Divider className='w-64' />
                </div>
                <TextFieldFormsy
                    className='mb-16'
                    size='small'
                    type='password'
                    name='password'
                    label='Password'
                    validations={{
                        passwordStrengh: (values, value) => {
                            if (value) {
                                const schema = yup
                                    .string()
                                    .min(8, 'Password needs at least 8 characters')
                                    .matches(/[0-9]/, 'Password needs at least one number')
                                    .matches(/[A-Z]/, 'Password needs at least one uppercase letter')
                                    .matches(/[a-z]/, 'Password needs at least one lowercase letter');

                                try {
                                    schema.validateSync(value);
                                } catch (err) {
                                    return err.message;
                                }
                                return true;
                            }
                            return true;
                        },
                        equalsField: 'password-confirm',
                    }}
                    validationErrors={{
                        equalsField: 'Passwords do not match',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    vpn_key
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                    disabled={register.loading}
                    value=''
                />
                <TextFieldFormsy
                    className='mb-16'
                    size='small'
                    type='password'
                    name='password-confirm'
                    label='Confirm Password'
                    validations='equalsField:password'
                    validationErrors={{
                        equalsField: 'Passwords do not match',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    vpn_key
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                    disabled={register.loading}
                    value=''
                />
                <div className='mb-16 mt-8 flex items-center justify-center'>
                    <Divider className='w-64' />
                </div>
                <div className=''>
                    <CheckboxFormsy
                        name='confirm'
                        value={false}
                        label={
                            <div className=''>
                                I confirm that I have read and agree to the{' '}
                                <a
                                    target='_new'
                                    href='https://microfan.com/wp-content/uploads/2020/12/BRAVO.CLOUD-Terms-and-Conditions-v1.0.pdf'
                                >
                                    Terms and Conditions
                                </a>{' '}
                                by creating my user account.
                            </div>
                        }
                        validations={{
                            equals: true,
                        }}
                    />
                </div>

                {register.loading ? (
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className='w-full mx-auto mt-16 normal-case'
                        aria-label='LOADING'
                        disabled
                        value='legacy'
                    >
                        Loading ...
                    </Button>
                ) : (
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className='w-full mx-auto mt-16 normal-case'
                        aria-label='REGISTER'
                        disabled={!isFormValid}
                        value='legacy'
                    >
                        Register
                    </Button>
                )}
            </Formsy>
        </div>
    );
}

export default RegisterForm;
