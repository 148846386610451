import { red, green } from '@material-ui/core/colors';

const themesConfig = {
    bravo_cloud: {
        palette: {
            type: 'light',
            primary: {
                light: '#548BDE',
                main: '#05458d',
                dark: '#112f5b',
            },
            secondary: {
                light: '#ffdfb7',
                main: '#f18500',
                dark: '#da8e10',
                contrastText: '#FFFFFF',
            },
            background: {
                paper: '#FFFFFF',
                default: '#f3f3f3',
            },
            error: red,
        },
        status: {
            danger: 'orange',
        },
    },
};

export default themesConfig;
