import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import SettingsPanel from 'app/fuse-layouts/shared-components/SettingsPanel';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import FooterBravoCloud from './components/FooterBravoCloud';
import LeftSideBravoCloud from './components/LeftSideBravoCloud';
import NavbarWrapperBravoCloud from './components/NavbarWrapperBravoCloud';
import RightSideBravoCloud from './components/RightSideBravoCloud';
import ToolbarBravoCloud from './components/ToolbarBravoCloud';
import EnvironmentBravoCloud from './components/EnvironmentBravoCloud';
import MFSubscriptionStatusBanner from '@microfan/core/MFSubscriptionStatusBanner';
import MFDebugBanner from '@microfan/core/MFDebugBanner';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        '&.boxed': {
            maxWidth: 1280,
            margin: '0 auto',
            boxShadow: theme.shadows[3],
        },
        '&.scroll-body': {
            '& $wrapper': {
                height: 'auto',
                flex: '0 0 auto',
                overflow: 'auto',
            },
            '& $contentWrapper': {},
            '& $content': {},
        },
        '&.scroll-content': {
            '& $wrapper': {},
            '& $contentWrapper': {},
            '& $content': {},
        },
        '& .navigation': {
            '& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
                transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                    easing: theme.transitions.easing.easeInOut,
                }),
            },
        },
    },
    wrapper: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: '1 1 auto',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 3,
        overflow: 'hidden',
        flex: '1 1 auto',
    },
    content: {
        position: 'relative',
        display: 'flex',
        overflow: 'auto',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%',
        '-webkit-overflow-scrolling': 'touch',
        zIndex: 2,
    },
}));

function BravoCloudLayout(props) {
    const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);

    const appContext = useContext(AppContext);
    const classes = useStyles(props);
    const { routes } = appContext;

    // console.warn('FuseLayout:: rendered');

    switch (config.scroll) {
        case 'body': {
            return (
                <div id='fuse-layout' className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}>
                    {config.leftSidePanel.display && <LeftSideBravoCloud />}

                    <div className='flex flex-1 flex-col overflow-hidden relative'>
                        {/* <EnvironmentBravoCloud /> */}
                        <MFDebugBanner />
                        <MFSubscriptionStatusBanner />

                        {config.toolbar.display &&
                            config.toolbar.style === 'fixed' &&
                            config.toolbar.position === 'above' && <ToolbarBravoCloud />}

                        <FuseScrollbars className='overflow-auto' scrollToTopOnRouteChange>
                            {config.toolbar.display &&
                                config.toolbar.style !== 'fixed' &&
                                config.toolbar.position === 'above' && <ToolbarBravoCloud />}

                            <div className={classes.wrapper}>
                                {config.navbar.display && config.navbar.position === 'left' && (
                                    <NavbarWrapperBravoCloud />
                                )}

                                <div className={classes.contentWrapper}>
                                    {config.toolbar.display && config.toolbar.position === 'below' && (
                                        <ToolbarBravoCloud />
                                    )}

                                    <div className={classes.content}>
                                        <FuseDialog />

                                        <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                                        {props.children}
                                    </div>

                                    {config.footer.display && config.footer.position === 'below' && (
                                        <FooterBravoCloud />
                                    )}

                                    {/* <SettingsPanel /> */}
                                </div>

                                {config.navbar.display && config.navbar.position === 'right' && (
                                    <NavbarWrapperBravoCloud />
                                )}
                            </div>

                            {config.footer.display &&
                                config.footer.style !== 'fixed' &&
                                config.footer.position === 'above' && <FooterBravoCloud />}
                        </FuseScrollbars>

                        {config.footer.display &&
                            config.footer.style === 'fixed' &&
                            config.footer.position === 'above' && <FooterBravoCloud />}
                    </div>

                    {config.rightSidePanel.display && <RightSideBravoCloud />}

                    <FuseMessage />
                </div>
            );
        }
        case 'content':
        default: {
            return (
                <div id='fuse-layout' className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}>
                    {config.leftSidePanel.display && <LeftSideBravoCloud />}

                    <div className='flex flex-1 flex-col overflow-hidden relative'>
                        {/* <EnvironmentBravoCloud /> */}
                        <MFDebugBanner />
                        <MFSubscriptionStatusBanner />

                        {config.toolbar.display && config.toolbar.position === 'above' && <ToolbarBravoCloud />}

                        <div className={classes.wrapper}>
                            {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperBravoCloud />}

                            <div className={classes.contentWrapper}>
                                {config.toolbar.display &&
                                    config.toolbar.position === 'below' &&
                                    config.toolbar.style === 'fixed' && <ToolbarBravoCloud />}

                                <FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
                                    {config.toolbar.display &&
                                        config.toolbar.position === 'below' &&
                                        config.toolbar.style !== 'fixed' && <ToolbarBravoCloud />}

                                    <FuseDialog />

                                    <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                                    {props.children}

                                    {config.footer.display &&
                                        config.footer.position === 'below' &&
                                        config.footer.style !== 'fixed' && <FooterBravoCloud />}
                                </FuseScrollbars>

                                {config.footer.display &&
                                    config.footer.position === 'below' &&
                                    config.footer.style === 'fixed' && <FooterBravoCloud />}

                                {/* <SettingsPanel /> */}
                            </div>

                            {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperBravoCloud />}
                        </div>

                        {config.footer.display && config.footer.position === 'above' && <FooterBravoCloud />}
                    </div>

                    {config.rightSidePanel.display && <RightSideBravoCloud />}

                    <FuseMessage />
                </div>
            );
        }
    }
}

export default React.memo(BravoCloudLayout);
