import React from 'react';

const JoinCompanyConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: '/join-company',
            component: React.lazy(() => import('./JoinCompany')),
        },
    ],
};

export default JoinCompanyConfig;
