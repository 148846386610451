import React from 'react';
import { Redirect } from 'react-router-dom';

const CompanyAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        // // company --> profile
        // {
        //     path: '/apps/company-app/profile',
        //     component: React.lazy(() => import('./profile/Profile')),
        // },
        // company --> employees
        {
            path: '/apps/company-app/employees',
            component: React.lazy(() => import('./employees/Employees')),
        },
        // company --> subscription
        {
            path: '/apps/company-app/subscription',
            component: React.lazy(() => import('./subscription/Subscription')),
        },
        // redirects
        {
            path: '/apps/company-app',
            component: () => <Redirect to='/apps/company-app/employees' />,
        },
    ],
};

export default CompanyAppConfig;
