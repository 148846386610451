import Axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { getSubscriptionStart, getSubscriptionSuccess, getSubscriptionFailed } from 'app/store/bravo/subscriptionSlice';

function* loadSubscriptionAsync(action) {
    try {
        yield put(getSubscriptionStart());
        const { id: companyId } = action.payload;
        const response = yield Axios.get(`/api/companies/${companyId}/subscription`);
        const { data } = response;
        yield put(getSubscriptionSuccess(data));
    } catch (err) {
        yield put(getSubscriptionFailed(err));
    }
}

export default function* userSagas() {
    yield takeLatest('auth/user/updateActiveCompany', loadSubscriptionAsync);
}
