import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';
import { Typography } from '@material-ui/core';

function LoginForm(props) {
    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const formRef = useRef(null);

    useEffect(() => {
        if (login.error && (login.error.email || login.error.password)) {
            formRef.current.updateInputsWithError({
                ...login.error,
            });
            disableButton();
        }
    }, [login.error]);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        setIsFormValid(true);
    }

    function handleSubmit(model) {
        dispatch(submitLogin(model));
    }

    return (
        <div className='w-full'>
            <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className='flex flex-col justify-center w-full'
            >
                <TextFieldFormsy
                    className='mb-16'
                    type='text'
                    name='email'
                    label='Email'
                    value=''
                    validations={{
                        minLength: 4,
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 4',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    email
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                />

                <TextFieldFormsy
                    className='mb-16'
                    type='password'
                    name='password'
                    label='Password'
                    value=''
                    validations={{
                        minLength: 4,
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 4',
                    }}
                    InputProps={{
                        className: 'pr-2',
                        type: showPassword ? 'text' : 'password',
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    <Icon className='text-20' color='action'>
                                        {showPassword ? 'visibility' : 'visibility_off'}
                                    </Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                />

                {login.error && (
                    <Typography color='error' className='mb-16 text-center'>
                        {login.error === 'invalid_grant' && 'Invalid username or password'}
                    </Typography>
                )}

                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='w-full mx-auto normal-case'
                    aria-label='LOG IN'
                    disabled={!isFormValid}
                    value='legacy'
                >
                    Login
                </Button>
            </Formsy>
        </div>
    );
}

export default LoginForm;
