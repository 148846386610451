import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import user from './userSlice';
import mailConfirm from './mailConfirmSlice';
import validateEmail from './validateEmailSlice';
import companies from './companiesSlice';
import createCompany from './createCompanySlice';
import joinCompany from './joinCompanySlice';

const authReducers = combineReducers({
    user,
    login,
    register,
    mailConfirm,
    validateEmail,
    companies,
    createCompany,
    joinCompany,
});

export default authReducers;
