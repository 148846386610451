import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: null,
};

const registerSlice = createSlice({
    name: 'auth/register',
    initialState,
    reducers: {
        mailConfirmSuccess: (state, action) => {
            state.email = action.payload;
        },
    },
    extraReducers: {},
});

export const { mailConfirmSuccess } = registerSlice.actions;

export default registerSlice.reducer;
