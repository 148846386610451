const locale = {
    APPLICATIONS: 'Programlar',
    DASHBOARDS: 'Kontrol Paneli',
    CALENDAR: 'Takvim',
    ECOMMERCE: 'E-Ticaret',
    ACADEMY: 'Akademi',
    MAIL: 'Posta',
    TODO: 'Yapılacaklar',
    FILE_MANAGER: 'Dosya Yöneticisi',
    CONTACTS: 'Kişiler',
    CHAT: 'Sohbet',
    SCRUMBOARD: 'Proje',
    NOTES: 'Notlar',
};

export default locale;
