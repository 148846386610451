import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import LostPasswordConfig from 'app/main/forgot-password/ForgotPasswordConfig';
import ValidateEmailConfig from 'app/main/validate-email/ValidateEmailConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import RegisterConfig from 'app/main/register/RegisterConfig';
import React from 'react';
import { Redirect } from 'react-router-dom';
import MailConfirmConfig from 'app/main/mail-confirm/MailConfirmConfig';
import SelectCompanyConfig from 'app/main/select-company/SelectCompanyConfig';
import CreateCompanyConfig from 'app/main/create-company/CreateCompanyConfig';
import JoinCompanyConfig from 'app/main/join-company/JoinCompanyConfig';
import StartSubscriptionConfig from 'app/main/start-subscription/StartSubscriptionConfig';
import ResetPasswordConfig from 'app/main/reset-password/ResetPasswordConfig';

const routeConfigs = [
    ...appsConfigs,
    ...pagesConfigs,
    LogoutConfig,
    LoginConfig,
    RegisterConfig,
    LostPasswordConfig,
    LogoutConfig,
    ValidateEmailConfig,
    MailConfirmConfig,
    SelectCompanyConfig,
    CreateCompanyConfig,
    JoinCompanyConfig,
    StartSubscriptionConfig,
    ResetPasswordConfig,
];

const routes = [
    // if you want to make whole app auth protected by default change defaultAuth for example:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['bravo-admin', 'bravo-default']),
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/apps/devices-app' />,
    },
    {
        component: () => <Redirect to='/pages/errors/error-404' />,
    },
];

export default routes;
