import { makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import FuseAnimate from '@fuse/core/FuseAnimate';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'rgb(138,0,0)',
        color: 'white',
    },
}));

const MFDebugBanner = (props) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return (
            <Message {...props}>
                <span>SocketId: {window.socketId}</span>
            </Message>
        );
    }

    return null;
};

const Message = (props) => {
    const classes = useStyles(props);
    return (
        <div className={clsx(classes.root, 'flex flex-row justify-center items-center p-y1')}>
            <FuseAnimate delay={400} animation='transition.slideDownIn'>
                <div>{props.children}</div>
            </FuseAnimate>
        </div>
    );
};

export default React.memo(MFDebugBanner);
