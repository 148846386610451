import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getCompanies } from 'app/auth/store/companiesSlice';

export const createCompany = createAsyncThunk(
    'auth/createCompany/createCompany',
    async (model, { dispatch, getState }) => {
        try {
            dispatch(setCreateCompanyLoadingState(true));
            const response = await Axios.post(`/api/companies`, model);
            const data = await response.data;
            dispatch(getCompanies());
            dispatch(setCreateCompanyLoadingState(false));
        } catch (err) {
            // TODO: catch all errors
        }
    }
);

const initialState = {
    loading: false,
    error: null,
};

const createCompanySlice = createSlice({
    name: 'auth/createCompany',
    initialState,
    reducers: {
        setCreateCompanyLoadingState: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: {
        // [getCompanies.fulfilled]: (state, action) => action.payload,
    },
});

export const { createCompanySuccess, createCompanyFailed, setCreateCompanyLoadingState } = createCompanySlice.actions;

export default createCompanySlice.reducer;
