import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getActiveCompany } from 'app/auth/store/userSlice';
import { getActiveSubscription } from 'app/store/bravo/subscriptionSlice';
import FuseAnimate from '@fuse/core/FuseAnimate';
import SubScriptionUtil from '@microfan/utils/SubscriptionUtil';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#ffd31d',
    },
}));

const MFSubscriptionStatusBanner = (props) => {
    const activeCompany = useSelector(getActiveCompany());
    const activeSubscription = useSelector(getActiveSubscription());
    const history = useHistory();

    if (!activeCompany || !activeSubscription) {
        return null;
    }

    const { isOwner } = activeCompany;
    // const isOwner = true;

    if (activeSubscription.status === 'trialing') {
        const trialingExpirationInDays = SubScriptionUtil.trialingExpirationInDays(activeSubscription);
        return (
            <Message {...props}>
                {trialingExpirationInDays > 0 && (
                    <>
                        <span>
                            Trial period for <strong>{activeCompany.companyName}</strong> ends in{' '}
                            <strong>{trialingExpirationInDays}</strong> day{trialingExpirationInDays > 1 && 's'}, please
                            upgrade your subscription.
                        </span>
                        {isOwner && (
                            <Button
                                size='small'
                                className='mx-16'
                                variant='outlined'
                                onClick={() => history.push('/apps/company-app/subscription')}
                            >
                                Upgrade
                            </Button>
                        )}
                    </>
                )}
            </Message>
        );
    }

    if (activeSubscription.status === 'trial_expired') {
        return (
            <Message {...props}>
                <span>
                    Trial period for <strong>{activeCompany.companyName}</strong> is expired, please purchase your
                    subscription.
                </span>
                {isOwner && (
                    <Button
                        size='small'
                        className='mx-16'
                        variant='outlined'
                        onClick={() => history.push('/apps/company-app/subscription')}
                    >
                        Purchase
                    </Button>
                )}
            </Message>
        );
    }

    if (activeSubscription.status === 'canceled') {
        return (
            <Message {...props}>
                <span>Subscription is canceled, please start a new subscription.</span>
                {isOwner && (
                    <Button
                        size='small'
                        className='mx-16'
                        variant='outlined'
                        onClick={() => history.push('/apps/company-app/subscription')}
                    >
                        Start
                    </Button>
                )}
            </Message>
        );
    }

    if (activeSubscription.status === 'canceled_at_period_end') {
        const cancelExpirationInDays = SubScriptionUtil.cancelExpirationInDays(activeSubscription);
        return (
            <Message {...props}>
                <span>
                    Subscription for <strong>{activeCompany.companyName}</strong> is canceled and will end in{' '}
                    <strong>{cancelExpirationInDays}</strong> day{cancelExpirationInDays > 1 && 's'}
                </span>
                {isOwner && (
                    <Button
                        size='small'
                        className='mx-16'
                        variant='outlined'
                        onClick={() => history.push('/apps/company-app/subscription')}
                    >
                        Reactivate
                    </Button>
                )}
            </Message>
        );
    }

    // if (activeSubscription.status === 'past_due' && isOwner) {
    //     return (
    //         <Message {...props}>
    //             <span>Subscription is past-due, please update your payment information. </span>
    //             <Button size='small' className='mx-16' variant='outlined' onClick={() => history.push('/apps/company-app/subscription')}>
    //                 Update
    //             </Button>
    //         </Message>
    //     );
    // }

    return null;
};

const Message = (props) => {
    const classes = useStyles(props);
    return (
        <div className={clsx(classes.root, 'flex flex-row justify-center items-center py-8')}>
            <FuseAnimate delay={400} animation='transition.slideDownIn'>
                <div>{props.children}</div>
            </FuseAnimate>
        </div>
    );
};

export default React.memo(MFSubscriptionStatusBanner);
