import Axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { getDevicesStart, getDevicesSuccess, getDevicesFailed } from 'app/store/bravo/devicesSlice';

function* loadDevicesAsync(action) {
    try {
        yield put(getDevicesStart());
        const { id: companyId } = action.payload;
        const response = yield Axios.get(`/api/companies/${companyId}/devices`);
        const data = yield response.data;
        yield put(getDevicesSuccess(data));
    } catch (err) {
        yield put(getDevicesFailed(err));
    }
}

export default function* userSagas() {
    yield takeLatest('bravo/devices/getDevices', loadDevicesAsync);
    yield takeLatest('auth/user/updateActiveCompany', loadDevicesAsync);
}
