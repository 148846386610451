import Axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { getEmployeesStart, getEmployeesSuccess, getEmployeesFailed } from 'app/store/bravo/employeesSlice';

function* loadEmployeesAsync(action) {
    try {
        yield put(getEmployeesStart());
        const { id: companyId } = action.payload;
        const response = yield Axios.get(`/api/companies/${companyId}/employees`);
        const { data } = response;
        yield put(getEmployeesSuccess(data));
    } catch (err) {
        yield put(getEmployeesFailed(err));
    }
}

export default function* employeesSagas() {
    yield takeLatest('bravo/devices/getEmployees', loadEmployeesAsync);
    yield takeLatest('auth/user/updateActiveCompany', loadEmployeesAsync);
}
