const settingsConfig = {
    layout: {
        style: 'layout_bravo_cloud', // layout-1 layout-2 layout-3
        config: {}, // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
    },
    customScrollbars: true,
    animations: true,
    direction: 'ltr', // rtl, ltr
    theme: {
        main: 'bravo_cloud',
        navbar: 'bravo_cloud',
        toolbar: 'bravo_cloud',
        footer: 'bravo_cloud',
    },
};

export default settingsConfig;
