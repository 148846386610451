import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import fuse from './fuse';
import bravo from './bravo';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        bravo,
        ...asyncReducers,
    });

export default createReducer;
