import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';

const initialState = {
    success: false,
    loading: false,
    error: null,
};

export const validateEmail = (email, token) => async (dispatch) => {
    Axios.create({
        baseURL: process.env.REACT_APP_AUTH_SERVER_API_HOST,
    })
        .get(`api/users/validate_email?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`)
        .then(() => dispatch(validateEmailSuccess()))
        .catch((err) => dispatch(validateEmailFailed(err.response.data.message)));
};

const validateEmailSlice = createSlice({
    name: 'auth/register',
    initialState,
    reducers: {
        validateEmailSetLoadingState: (state, action) => {
            state.loading = true;
        },
        validateEmailSuccess: (state, action) => {
            state.loading = false;
            state.success = true;
        },
        validateEmailFailed: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
        },
    },
    extraReducers: {},
});

export const { validateEmailSuccess, validateEmailFailed } = validateEmailSlice.actions;

export default validateEmailSlice.reducer;
