import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${'assets/images/auth/login-background.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
    },
}));

function MailConfirmPage() {
    const classes = useStyles();
    const mailConfirm = useSelector(({ auth }) => auth.mailConfirm);
    return (
        <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
            <div className='flex flex-col items-center justify-center w-full'>
                <FuseAnimate animation='transition.expandIn'>
                    <Card className='w-full max-w-512 rounded-8'>
                        <CardContent className='flex flex-col items-center justify-center p-32'>
                            <div className='m-32'>
                                <Icon className='text-96' color='action'>
                                    email
                                </Icon>
                            </div>

                            <Typography variant='h5' className='text-center mb-16'>
                                Confirm your email address!
                            </Typography>

                            <Typography className='text-center mb-16 w-full' color='textSecondary'>
                                A confirmation email has been sent to <b>{mailConfirm.email}</b>.
                            </Typography>

                            <Typography className='text-center w-full' color='textSecondary'>
                                Check your inbox and click on the confirmation link to confirm your email address.
                            </Typography>

                            <div className='flex flex-col items-center justify-center pt-32 pb-24'>
                                <Link className='font-medium' to='/pages/auth/login'>
                                    Go back to login
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>
    );
}

export default MailConfirmPage;
