import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';

const useStyles = makeStyles((theme) => ({
    root: {
        background: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${darken(
            theme.palette.primary.main,
            0.5
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },
    visual: {
        backgroundImage: `url(${'assets/images/auth/login-background.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
    },
}));

function Login() {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}>
            <Hidden smDown>
                <div className={clsx(classes.visual, 'flex flex-col flex-auto')}>
                    <div className='flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left'>
                        <FuseAnimate animation='transition.expandIn'>
                            <img
                                className='w-320 mb-32'
                                src='assets/images/corporate-identity/bravo-logo.svg'
                                alt='bravo logo'
                            />
                        </FuseAnimate>

                        <FuseAnimate animation='transition.slideUpIn' delay={300}>
                            <Typography variant='h3' color='inherit' className='font-light'>
                                DISCOVER BRAVO.CLOUD
                                <br />
                                The easy farming solution
                            </Typography>
                        </FuseAnimate>

                        <FuseAnimate delay={400}>
                            <Typography variant='subtitle1' color='inherit' className='max-w-512 mt-16'>
                                Control your climate control system with the easu-to-use BRAVO.CLOUD app on your
                                smartphone, from anywhere
                            </Typography>
                        </FuseAnimate>
                    </div>
                </div>
            </Hidden>

            <Hidden only={['xl', 'lg', 'md', 'xs']}>
                <FuseAnimate animation='transition.expandIn'>
                    <img
                        className='w-full max-w-400 mb-16 mx-auto'
                        src='assets/images/corporate-identity/bravo-logo.svg'
                        alt='bravo logo'
                    />
                </FuseAnimate>
            </Hidden>

            <Card className='w-full max-w-400 mx-auto m-16 md:m-0' square>
                <CardContent className='flex flex-col items-center justify-center p-32 md:p-48 md:pt-64 '>
                    <img
                        className='w-full mb-32'
                        src='assets/images/corporate-identity/microfan-logo-text.png'
                        alt='microfan logo'
                    />

                    <Typography variant='h6' className='md:w-full mb-32'>
                        LOGIN TO YOUR ACCOUNT
                    </Typography>

                    <LoginForm />

                    <div className='flex flex-col items-center justify-center pt-32 pb-24'>
                        <span className='font-medium'>Don't have an account?</span>
                        <Link className='font-medium' to='/register'>
                            Create an account
                        </Link>
                    </div>

                    <div className='flex flex-col items-center justify-center pb-24'>
                        <span className='font-medium'>Already have an account?</span>
                        <Link className='font-medium' to='/forgot_password'>
                            Recover password
                        </Link>
                    </div>
                    <div className='flex flex-col items-center justify-center pb-24'>
                        <span className='font-medium'>
                            Back to <a href='https://www.bravo.cloud'>BRAVO.CLOUD</a> homepage
                        </span>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default Login;
