import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useEffect } from 'react';
import { validateEmail } from 'app/auth/store/validateEmailSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
        color: theme.palette.primary.contrastText,
    },
}));

function ValidateEmail() {
    const classes = useStyles();
    const location = useLocation();
    const validateEmailState = useSelector(({ auth }) => auth.validateEmail);
    const { email, token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(validateEmail(email, token));
    }, []);

    return (
        <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
            <div className='flex flex-col items-center justify-center w-full'>
                <FuseAnimate animation='transition.expandIn'>
                    <Card className='w-full max-w-512 rounded-8'>
                        <CardContent className='flex flex-col items-center justify-center p-32'>
                            <div className='m-32'>
                                <Icon
                                    className='text-96'
                                    color={validateEmailState.success ? 'action' : 'error'}
                                    style={{ color: validateEmailState.success && 'green' }}
                                >
                                    email
                                </Icon>
                            </div>

                            <Typography
                                variant='h5'
                                className='text-center mb-16'
                                color={validateEmailState.success ? 'action' : 'error'}
                                style={{ color: validateEmailState.success && 'green' }}
                            >
                                {validateEmailState.success
                                    ? `Successfully validated email!`
                                    : `Failed to validate email!`}
                            </Typography>

                            {validateEmailState.success ? (
                                <Typography className='text-center mb-16 w-full' color='textSecondary'>
                                    Email address <b>{email}</b> is successfully validated.
                                </Typography>
                            ) : (
                                <Typography className='text-center mb-16 w-full' color='textSecondary'>
                                    Email address <b>{email}</b> failed to validated, reason:
                                </Typography>
                            )}

                            {!validateEmailState.success && (
                                <Typography className='text-center w-full' color='textSecondary'>
                                    {validateEmailState.error}
                                </Typography>
                            )}

                            <div className='flex flex-col items-center justify-center pt-32 pb-24'>
                                <Link
                                    className='font-medium'
                                    to={`/login${validateEmailState.success ? `?email=${email}` : ''}`}
                                >
                                    {validateEmailState.success ? 'Continue to login' : 'Close'}
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>
    );
}

export default ValidateEmail;
