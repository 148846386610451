import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import createReducer from './rootReducer';
import sagas from './sagas';

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer.createReducer());
    });
}

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});
// sagaMiddleware.run(sagas);

const middleware = [...getDefaultMiddleware({ thunk: true }), sagaMiddleware];

const store = configureStore({
    reducer: createReducer(),
    // middleware: (getDefaultMiddleware) => {
    //     // if (process.env.NODE_ENV === 'development') {
    //     //     const { logger } = require(`redux-logger`);

    //     //     return getDefaultMiddleware().concat(logger);
    //     // }

    //     return getDefaultMiddleware().concat(sagaMiddleware);
    // },
    middleware,
    devTools: process.env.NODE_ENV === 'development',
    enhancers: [sentryReduxEnhancer],
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
    return store;
};

sagaMiddleware.run(sagas);

export default store;
