import React from 'react';

const StartSubscriptionConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: '/start-subscription',
            component: React.lazy(() => import('./StartSubscription')),
        },
    ],
};

export default StartSubscriptionConfig;
