import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getCompanies } from 'app/auth/store/companiesSlice';

export const joinCompany = createAsyncThunk('auth/joinCompany/joinCompany', async (model, { dispatch, getState }) => {
    try {
        dispatch(setJoinCompanyLoadingState(true));
        const { id: employeeId } = getState().auth.user.data;
        const response = await Axios.post(`/api/employees/${employeeId}/companies`, {
            companyCode: `${model.companyCode1}-${model.companyCode2}`,
            companyPin: model.companyPin,
        });
        const data = await response.data;
        dispatch(getCompanies());
        dispatch(setJoinCompanyLoadingState(false));
    } catch (err) {
        // TODO: catch all errors
    }
});

const initialState = {
    loading: false,
    error: null,
};

const joinCompanySlice = createSlice({
    name: 'auth/joinCompany',
    initialState,
    reducers: {
        setJoinCompanyLoadingState: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: {},
});

export const { setJoinCompanyLoadingState } = joinCompanySlice.actions;

export default joinCompanySlice.reducer;
